<template>
    <div class="worckspace__body">
        <page-header title='Упаковка' icon="box" :navlinks="navlinks" />
        <router-view></router-view>
        <table class="control-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Название</th>
                    <th>Тип</th>
                    <th>Материал</th>
                    <th>Код</th>
                    <th>Изображение</th>
                    <th>Вместимость</th>
                    <th>Вес</th>
                    <th>Размер, см</th>
                    <th>Симол года</th>
                    <th>Якутская упаковка</th>
                    <th>В индивидуальных</th>
                    <th>Ярлык</th>
                    <th>Опубликовано</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'Packs',
    data(){
        return {
            navlinks: [
                {
                    title: 'Добавить упаковку',
                    route: '/packs/new',
                    icon: 'plus'
                },
                {
                    title: 'Типы',
                    route: '/packs/types',
                    icon: 'list'
                },
                {
                    title: 'Материалы',
                    route: '/packs',
                    icon: 'list'
                }
            ]
        }
    }
}
</script>